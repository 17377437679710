// src/context/auth.js

import React, { useState, useEffect, createContext, useContext } from 'react';
import {
  firestore,
  auth,
  FieldValue,
  initializeSubscription,
} from '../services/firebase';
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  updateProfile,
  sendEmailVerification,
  signOut,
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth) {
      const unsubscribe = onAuthStateChanged(auth, user => {
        setUser(user);
        setLoading(false);
      });
      return unsubscribe;
    } else {
      setLoading(false);
    }
  }, []);

  const login = async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    if (!userCredential.user.emailVerified) {
      throw new Error('auth/email-not-verified'); // This key should be translated in the login component
    }
    return userCredential;
  };

  const logout = async () => {
    await signOut(auth);
  };

  const resetPassword = async email => {
    await sendPasswordResetEmail(auth, email);
  };

  const signup = async (email, displayName, password) => {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    await sendEmailVerification(userCredential.user);

    if (auth.currentUser) {
      await updateProfile(auth.currentUser, {
        displayName,
      });

      const createdAt = FieldValue.serverTimestamp();

      await setDoc(doc(firestore, 'users', userCredential.user.uid), {
        displayName,
        createdAt,
      });

      await initializeSubscription(userCredential.user.uid);
    }

    return userCredential;
  };

  const updatePasswordFunction = async newPassword => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      await updatePassword(currentUser, newPassword);
    }
  };

  const sendPasswordReset = async email => {
    try {
      await sendPasswordResetEmail(auth, email);
      return 'Reset email sent successfully. Please check your email.';
    } catch (error) {
      throw new Error(
        'Failed to send reset email. Please check the email address and try again.'
      );
    }
  };

  const value = {
    user,
    loading,
    setUser,
    signup,
    login,
    logout,
    sendEmailVerification,
    resetPassword,
    updatePassword: updatePasswordFunction,
    sendPasswordReset,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
