module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es","ca"],"defaultLanguage":"en","siteUrl":"http://relevantai.tech/","i18nextOptions":{"fallbackLng":"en","interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":":"},"pages":[{"matchPath":"/:lang?/privacy-policy","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Relevant AI","short_name":"Relevant AI","description":"Relevant AI revolutionizes the legal industry with advanced AI tools that enhance productivity, streamline operations, and ensure the highest standards of security and accuracy.","start_url":"/","lang":"en","background_color":"#f5f5f5","theme_color":"#ff5700","display":"minimal-ui","icon":"static/images/icon-512x512.png","legacy":false,"screenshots":[{"src":"static/images/screenshot_wide.jpg","sizes":"1280x722","label":"Relevant AI App","type":"image/jpeg"},{"src":"static/images/screenshot_narrow.jpg","sizes":"720x1281","label":"Relevant AI App","type":"image/jpeg"}],"localize":[{"start_url":"/es/","lang":"es","name":"Relevant AI","short_name":"Relevant AI","description":"Relevant AI revoluciona la industria legal con herramientas avanzadas de IA que mejoran la productividad, optimizan las operaciones y garantizan los más altos estándares de seguridad y precisión."},{"start_url":"/ca/","lang":"ca","name":"Relevant AI","short_name":"Relevant AI","description":"Relevant AI revoluciona la indústria legal amb eines avançades d'IA que milloren la productivitat, optimitzen les operacions i garanteixen els estàndards més alts de seguretat i precisió."}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e7849bd9346d3ed604c7c621e02e9393"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
