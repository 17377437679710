// src/firebase.js

import { initializeApp } from 'firebase/app';
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import {
  getFirestore,
  serverTimestamp,
  setDoc,
  doc,
  getDoc,
  updateDoc,
  collection,
  addDoc,
} from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
};

let app = null;
let auth = null;
let firestore = null;
let analytics = null;
let cloudFunctions = null;

if (typeof window !== 'undefined') {
  app = initializeApp(firebaseConfig);
  auth = getAuth(app);
  firestore = getFirestore(app);
  cloudFunctions = getFunctions(app);

  if (auth) {
    setPersistence(auth, browserSessionPersistence);
  }

  // Lazy load Firebase Analytics
  import('firebase/analytics').then(({ getAnalytics }) => {
    analytics = getAnalytics(app);
  });
}

const addEmailToSubscription = async email => {
  const emailRef = collection(firestore, 'subscriptions');
  await addDoc(emailRef, {
    email: email,
    timestamp: serverTimestamp(),
  });
};

const initializeSubscription = async userId => {
  const subscriptionRef = doc(firestore, 'service_subscriptions', userId);
  await setDoc(subscriptionRef, {
    userId: userId,
    queriesRemaining: 20, // default free tier queries
    tier: 'free',
    paymentInfo: null, // add appropriate payment information if needed
    timestamp: serverTimestamp(),
  });
};

const decrementQueryCount = async userId => {
  const subscriptionRef = doc(firestore, 'service_subscriptions', userId);
  try {
    const subscriptionDoc = await getDoc(subscriptionRef);
    if (!subscriptionDoc.exists()) {
      throw new Error('Subscription document does not exist!');
    }

    const currentQueriesRemaining = subscriptionDoc.data().queriesRemaining;
    if (currentQueriesRemaining <= 0) {
      throw new Error('No queries remaining');
    }

    await updateDoc(subscriptionRef, {
      queriesRemaining: currentQueriesRemaining - 1,
    });
  } catch (error) {
    console.error('Failed to decrement query count: ', error);
    throw error;
  }
};

const getSubscription = async userId => {
  const subscriptionRef = doc(firestore, 'service_subscriptions', userId);
  const docSnap = await getDoc(subscriptionRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    throw new Error('No subscription data found!');
  }
};

const FieldValue = { serverTimestamp };

export {
  auth,
  firestore,
  analytics,
  cloudFunctions,
  FieldValue,
  addEmailToSubscription,
  initializeSubscription,
  decrementQueryCount,
  getSubscription,

  // from firebase/auth
  // signInWithEmailAndPassword,
  // createUserWithEmailAndPassword,
  // sendEmailVerification,
  // sendPasswordResetEmail,
  // updateProfile,
  // updatePassword,
  // signOut,
  // onAuthStateChanged,

  // from firebase/firestore
  // collection,
  // query,
  // where,
  // orderBy,
  // onSnapshot,
  // doc,
  // setDoc,
  // updateDoc,
  // deleteDoc,
};
